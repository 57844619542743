import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Page from '../components/Page';
import Header from '../components/Header';
import Content from '../components/styles/Content';
import PageTitle from '../components/styles/PageTitle';
import Github from '../../resources/icons/github';
import Linkedin from '../../resources/icons/linkedin';
import ContactForm from '../components/ContactForm';

const Icons = styled.div`
  > a {
    fill: ${props => props.theme.textColor};
    font-size: 4rem;
    margin-right: 3rem;
  }
`;
const linkData = [
  { url: 'https://github.com/ebozkurt93', icon: <Github /> },
  {
    url: 'https://linkedin.com/in/erdem-bozkurt-815325158/',
    icon: <Linkedin />
  },
];

const ContactPage = () => {
  const [state, setState] = useState({
    email: 'erdem@erdem-bozkurt.com',
    visible: false
  });
  return (
    <Page>
      <>
        <Helmet title="Contact" />
        <Header contact />
        <Content>
          <PageTitle>Contact Me</PageTitle>
          <br />
          <div style={{ display: 'flex' }}>
            {'Email: '}
            {!state.visible && (
              <button
                style={{ marginLeft: '2rem' }}
                onClick={() => {
                  setState({ ...state, visible: true });
                }}
              >
                show email address
              </button>
            )}
            {state.visible && (
              <a
                style={{ marginLeft: '2rem' }}
                href={'mailto:' + state.email}
              >
                {state.email}
              </a>
            )}
          </div>
          <div style={{ height: '3rem' }} />
          <Icons>
            {linkData.map(i => (
              <a
                key={i.url}
                href={i.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i.icon}
              </a>
            ))}
          </Icons>
          {process.env.GATSBY_CONTACT_FORM_ENABLED === "true" &&
            <>
              <div style={{ height: '3rem' }} />
              <ContactForm />
            </>
          }
        </Content>
      </>
    </Page>
  );

}

export default ContactPage;
