import React, { Component } from 'react';
import styled from 'styled-components';
import { changeRgbaOpacity } from '../helpers';

const StyledForm = styled.form`
  border: 1.5px solid ${props => props.theme.textColor};
  border-radius: 5px;
  padding: 2rem;
  max-width: 60rem;
  display: block;
  margin: auto 0;

  label {
    box-sizing: border-box;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    margin: 1rem 0;
  }
  input,
  textarea {
    font-family: ${props => props.theme.fontPrimary};
    font-size: 1.8rem;
    border: 1.5px solid
      ${props => {
    changeRgbaOpacity(props.theme.textColor, '0.5');
  }};
    padding: 1rem 1.5rem;
    border-radius: 5px;
    background: none;
    color: ${props => props.theme.textColor};
    width: 100%;
  }
  span {
    display: inline-block;
    padding-bottom: 0.5rem;
  }
  input:focus,
  textarea:focus {
    border: 1.5px solid ${props => props.theme.linkColor};
  }

  h3 {
    text-align: center;
  }
  .required {
    position: relative;
  }
  .required::after {
    content: '*';
    font-size: 2.2rem;
    position: absolute;
    transform: translate(20%, -30%);
  }
  .loading {
    opacity: 0.5;
  }
  .error {
    color: ${props => props.theme.secondaryColor};
  }
`;

class ContactForm extends Component {
  state = {
    submitted: false,
    loading: false,
    error: '',
    name: '',
    email: '',
    message: '',
    extra: '' //honeypot, if not empty we can ensure this is a spam
  };

  saveToState = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name !== '') this.setState({ error: '' });
  };

  submitForm = e => {
    this.setState({ loading: true, error: '' });
    e.preventDefault();
    const url = `${process.env.GATSBY_API_URL}/contact`;
    if (this.state.extra !== '') {
      this.setState({
        loading: false,
        error: 'Please no spam :)'
      });
      return;
    }
    if (!(this.state.message && this.state.message.trim().length)) {
      this.setState({
        loading: false,
        error: 'Message cannot be empty!',
        message: ''
      });
      return;
    }
    const obj = {
      Name: this.state.name,
      Email: this.state.email,
      Message: this.state.message
      // 'form-category': 'website-contact'
    };
    fetch(url, {
      method: 'POST',
      cors: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then(response => {
        if (response.ok && response.status === 200) {
          this.setState({ loading: false });
          this.setState({ submitted: true });
          // clear form at end
          this.resetFields();
        } else {
          this.setState({
            loading: false,
            error: 'Something went wrong, please try again.'
          });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({
            loading: false,
            error: 'Something went wrong, please try again.'
          });
        }
      });
  };
  resetFields() {
    this.setState({
      name: '',
      email: '',
      message: '',
      extra: ''
    });
  }
  render() {
    const form = (
      <StyledForm onSubmit={this.submitForm}>
        <h3>Contact Form</h3>
        <label htmlFor="name">
          <span>Name</span>
          <br />
          <input
            type="text"
            id="name"
            name="name"
            value={this.state.name}
            onChange={this.saveToState}
            readOnly={this.state.loading}
            className={this.state.loading ? 'loading' : ''}
          />
        </label>
        <br />
        <label htmlFor="email">
          <span>Email</span>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            value={this.state.email}
            onChange={this.saveToState}
            readOnly={this.state.loading}
            className={this.state.loading ? 'loading' : ''}
          />
        </label>
        <br />
        <label htmlFor="message">
          <span className="required">Message</span>
          <div />
          <textarea
            rows="6"
            type="text"
            id="message"
            name="message"
            value={this.state.message}
            onChange={this.saveToState}
            readOnly={this.state.loading}
            className={this.state.loading ? 'loading' : ''}
          />
        </label>
        {/* Added this field just for preventing spam, if this field is filled than don't send request to server. */}
        <input
          hidden
          type="text"
          id="extra"
          name="extra"
          value={this.state.extra}
          onChange={this.saveToState}
          readOnly={this.state.loading}
          className={this.state.loading ? 'loading' : ''}
        />
        <br />
        <button
          type="submit"
          style={{ display: 'block', margin: 'auto' }}
          disabled={this.state.loading}
        >
          Submit
        </button>
        {this.state.error && <p className="error">{this.state.error}</p>}
      </StyledForm>
    );
    const thanks = (
      <p>
        Thanks for the message, I'll be in touch.
        <br />
        Got another one?{' '}
        <span
          className="link_like"
          onClick={() => {
            this.setState({ submitted: false });
          }}
        >
          Click here
        </span>{' '}
        to send another message.
      </p>
    );
    if (!this.state.submitted) return form;
    else return thanks;
  }
}
export default ContactForm;
